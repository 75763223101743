import { noop } from 'lodash';

/** @type {import ('./EspMarkdown').EspMarkdownProps} */
const EspMarkdownDefaultProps = {
  avoidFollowLinks: false,
  customComponents: {},
  globalVariables: null,
  globalsAsUpperCase: false,
  isBarista: undefined,
  isScoped: false,
  loadKbArticle: noop,
  noAnchorLinks: false,
  noAnchorTag: false,
  noExternalLinks: false,
  noInternalLinks: false,
  noMarkdownEmphasis: false,
  noMarkdownLinks: false,
  openInternalLinkFromBarista: noop,
  paramValues: null,
  removeTenantHost: false,
  supportedNodes: [
    'strong',
    'link',
    'emphasis',
    'underline',
    'list',
    'listItem',
  ],
  text: '',
};

export default Object.freeze(EspMarkdownDefaultProps);
