import buildCompositeController from '../../utils/buildCompositeController';

// Redux Action
import baristaActions from '../../actions/baristaActions';
// Utils
import URLUtil from '../../utils/URLUtil';
// Globals
import EspEntities from '../../globals/EspEntities';
// Actions
import baristaThunks from '../../actions/baristaThunks';

const mapStateToProps = (state, ownProps) => ({
  channelID: ownProps.params ? ownProps.params.channelID : '',
  intentEid: ownProps.params ? ownProps.params.intentEid : '',
});

const mapDispatchToProps = (dispatch) => ({
  openBarista: (searchString = '', channelID = '', intentEid = '') => {
    let qRCodeParams;
    if (searchString) {
      qRCodeParams = URLUtil.getQRParamsFromQueryString(searchString);
    }

    // This opens the Barista Modal
    dispatch(
      baristaActions.openSupportChannel(
        qRCodeParams,
        channelID,
        true,
        intentEid
      )
    );

    // DEV-11659 Opening Modal on top of Barista Modal is parameters indicate it
    if (qRCodeParams && qRCodeParams.kbSource && qRCodeParams.kbId) {
      // id of article will depend on type
      const typeToId = {
        [EspEntities.CONFLUENCE_KB]: 'confluenceId',
        [EspEntities.ESP_KB]: 'responseEid',
        [EspEntities.KB_ARTICLE]: 'sys_id',
        [EspEntities.KB_NUMBER]: 'number',
      };

      const paramName = typeToId[qRCodeParams.kbSource];
      // this function does things differently depending on the key passed (confluenceId, responseEid, sys_id or number)
      dispatch(baristaThunks.openKbArticle({ [paramName]: qRCodeParams.kbId })); // gosh leaving this here, the problem is that if kb modal renders
      // before barista modal, then it will be in the foreground
    }
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
